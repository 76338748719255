import React, { useEffect } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { ResponsiveContainer } from '../atoms/Container';
import { CannonBreadcrumbs } from '../atoms';
import { useTableOrMobile } from '../hooks';
import { cannonStyled, useCannonStyletron } from '../theme';
import { IFileImage } from '../@types';
import UserManualLocks from '../components/sections/UserManualLocks';

export interface IData {
  allStrapiLockTypes: AllStrapiLockTypes;
  strapiUserManualsByLockType: StrapiUserManualsByLockType;
}

export interface AllStrapiLockTypes {
  nodes: INode[];
}

export interface INode {
  lockType: ILockType[];
}

export interface ILockType {
  displayImage: IFileImage;
  hierarchy: string;
  description: string;
  displayName: string;
  manual: IFileImage;
  slug: string;
}
export interface StrapiUserManualsByLockType {
  title: string;
  subtitle: string;
}

// GRAPHQL QUERY
const query = graphql`
  query {
    allStrapiLockTypes {
      nodes {
        lockType {
          displayImage {
            url
          }
          hierarchy
          slug
          description
          displayName
          manual {
            url
          }
        }
      }
    }
    strapiUserManualsByLockType {
      title
      subtitle
    }
  }
`;

// STYLED COMPONENTS

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '16px' : '10px',
    width: '100%',
    textAlign: 'left',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '50px 0px 0px 0px' : '50px 0px 0px 0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

// COMPONENT
const UserManualsByLockType: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const data = useStaticQuery<IData>(query);
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  // STATE

  // HANDLERS & FUNCTIONS

  // EFFECTS & CALLBACKS

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    } else {
      return window.location.replace(
        '/user-manuals/'
      );
    }
  }, []);

  // RENDER

  const { title = '', subtitle = '' } = data.strapiUserManualsByLockType;
  const { nodes = [] as INode[] } = data.allStrapiLockTypes;
  const { lockType = [] as ILockType[] } = nodes[0];

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              { title: 'Manuals', href: `/user-manuals` },
              { title: 'By lock type', href: `/user-manuals-by-lock-type` },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <UserManualLocks locks={lockType} title={title} subtitle={subtitle} />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default UserManualsByLockType;