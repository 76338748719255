import React, { useMemo } from 'react';
import { Button } from '../../../atoms';
import LockTypeCard from '../../../atoms/Cards/LockTypeCard';
import { useTableOrMobile } from '../../../hooks';
import { ILockType } from '../../../pages/user-manuals-by-lock-type';
import {
  cannonStyled,
  NSH3,
  NSH4,
  TWH3,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import { ButtonMobileContainer } from '../UserManualSafes';

// STYLED COMPONENTS

const SafesGrid = cannonStyled(
  'div',
  ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => ({
    display: 'grid',
    gridTemplateColumns: isMobile
      ? '1fr'
      : isTablet
      ? '1fr 1fr'
      : '1fr 1fr 1fr',
    gridGap: isMobile ? '50px' : '2.8vw',
  })
);

const MainContainer = cannonStyled('div', {
  padding: 0,
  marginTop: '150px',
  width: '100%',
  height: '100%',
});

const NoResultsContainer = cannonStyled('div', {
  padding: '20px',
  height: 'calc(100vh - 450px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TitleContainer = cannonStyled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

interface Props {
  locks: ILockType[];
  title: string;
  subtitle: string;
}

export const UserManualLocks = ({
  title = '',
  subtitle = '',
  locks = [] as ILockType[],
}: Props) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  // STATE

  // EFFECTS

  // HANDLERS & FUNCTIONS

  const regularLocks = useMemo(() => {
    return locks.filter((lock) => lock.hierarchy === 'Regular');
  }, [locks]);

  const complementaryLocks = useMemo(() => {
    return locks.filter((lock) => lock.hierarchy !== 'Regular');
  }, [locks]);
  // RENDER

  return (
    <MainContainer>
      <TitleContainer>
        <NSH3
          weight='bold'
          align='left'
          $style={{ marginBottom: '20px', fontSize: '44px' }}
          color={theme.cannonColors?.primaryBlue}
        >
          {title}
        </NSH3>
        {/* {!isMobile ? (
          <Button.RoundedButton
            color='primary'
            size='compact'
            onClick={() => {
              window.location.href = '/user-manuals';
            }}
          >
            Search by safe--
          </Button.RoundedButton>
        ) : (
          <></>
        )} */}
      </TitleContainer>
      <TWParagraph3
        $style={{ fontSize: '20px', paddingBottom: '40px' }}
        color='#111111'
        weight={400}
        align='left'
      >
        {subtitle}
      </TWParagraph3>
      {/* {isMobile ? (
        <ButtonMobileContainer>
          <Button.RoundedButton
            color='primary'
            size='compact'
            onClick={() => {
              window.location.href = '/user-manuals';
            }}
          >
            Search by safe--
          </Button.RoundedButton>
        </ButtonMobileContainer>
      ) : (
        <></>
      )} */}
      {regularLocks.length > 0 ? (
        <>
          <SafesGrid isTablet={isTablet} isMobile={isMobile}>
            {regularLocks.map((lock: ILockType) => (
              <LockTypeCard lock={lock} />
            ))}
          </SafesGrid>
        </>
      ) : (
        <NoResultsContainer>
          <NSH4
            style={{ fontSize: isMobile ? '25px' : '36px' }}
            align='center'
            weight={900}
            color={theme.cannonColors.primaryBlue}
          >
            Sorry, there are no matches for your search.
          </NSH4>
        </NoResultsContainer>
      )}
      {complementaryLocks.length > 0 ? (
        <div style={{ padding: '70px 0px 20px 0px' }}>
          <NSH3
            weight='bold'
            align='left'
            $style={{ marginBottom: '50px', fontSize: '36px' }}
            color={theme.cannonColors?.primaryBlue}
          >
            Complementary manuals:
          </NSH3>
          <SafesGrid isTablet={isTablet} isMobile={isMobile}>
            {complementaryLocks.map((lock: ILockType) => (
              <LockTypeCard isComplementary lock={lock} />
            ))}
          </SafesGrid>
        </div>
      ) : (
        <></>
      )}
    </MainContainer>
  );
};

export default UserManualLocks;