import { AspectRatioBox } from 'baseui/aspect-ratio-box';
import { ParagraphSmall } from 'baseui/typography';
import React, { useState } from 'react';
import { useAPIURL, useTableOrMobile } from '../../hooks';
import { useOrientation } from '../../hooks/useOrientation';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ICannonSafe } from '../../pages';
import { ILockType } from '../../pages/user-manuals-by-lock-type';
import {
  CannonSafeThemeT,
  cannonStyled,
  useCannonStyletron,
} from '../../theme/CannonSafesTheme';
import { TWParagraph3 } from '../../theme/Typography';
import ExpandIcon from '../../assets/expand-icon.svg';
import './UserManualCard.css';
import { Button } from '..';

// STYLED COMPONENTS

interface ICardContainer {
  isMobile?: boolean;
  isTablet?: boolean;
  isRotated?: boolean;
  containerWidth?: number;
  $theme?: CannonSafeThemeT;
}

const CardContainer = cannonStyled(AspectRatioBox, (props: ICardContainer) => ({
  margin: props.isMobile ? '0px' : props.$theme.sizing.scale300,
  marginBottom: props.isMobile ? '' : '',
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxHeight: '480px !important',
  textAlign: 'center',
  width: '100%',
  justifySelf: props.isMobile ? 'center' : 'normal',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  cursor: 'pointer',
  boxShadow: '0px 9px 23px rgba(244, 246, 251, 0.83)',
  borderRadius: '10px',
  ':hover': {},
}));

const Image = cannonStyled(
  'img',
  ({ isComplementary }: { isComplementary: boolean }) => ({
    height: '260px',
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...(isComplementary && {
      objectFit: 'cover',
      width: '100%',
    }),
  })
);

const ImageContainer = cannonStyled('div', (props: any) => ({
  background: props.$theme.cannonColors.primaryWhiteDark,
  padding: props.isComplementary ? '0px' : '10px',
  display: 'flex',
  justifyContent: 'center',
}));

const InfoContainer = cannonStyled('div', {
  paddingBottom: '20px',
  paddingTop: '5px',
  paddingRight: '20px',
  paddingLeft: '20px',
});

const ExpandIconContainer = cannonStyled(
  'div',
  ({ isHovered }: { isHovered: boolean }) => ({
    display: isHovered ? 'block' : 'none',
    position: 'absolute',
    right: '20px',
    top: '15px',
    boxShadow: '(0px 4px 8px rgba(0, 0, 0, 0.06))',
  })
);

interface Props {
  lock: ILockType;
  isComplementary?: boolean;
}

export const LockTypeCard = ({
  lock = {} as ILockType,
  isComplementary,
}: Props) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();
  const { width } = useWindowDimensions();

  // STATE

  const [isHovered, setIsHovered] = useState(false);

  // EFFECTS

  // HANDLERS & FUNCTIONS

  function handleCardClick(type: 'card' | 'icon') {
    const { slug = '', manual } = lock;
    const { url } = manual;
    if (type === 'card') {
      const _slug = slug.replace(/ /g, '-');
      window.location.href = `/user-manual/${_slug}`;
    } else {
      window.open(API_URL + url, '_blank');
    }
  }

  // RENDER

  const { displayImage = {} as any, displayName = '', description = '' } = lock;
  const { url = '' } = displayImage;

  return (
    <CardContainer
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleCardClick('card');
      }}
      isMobile={isMobile}
      isTablet={isTablet}
      containerWidth={width}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ImageContainer isComplementary={isComplementary}>
        {!isMobile ? (
          <ExpandIconContainer isHovered={isHovered}>
            <Button.IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleCardClick('icon');
              }}
              $style={{
                backgroundColor: theme.cannonColors.primaryWhiteLight,
                borderRadius: '50%',
              }}
            >
              <ExpandIcon />
            </Button.IconButton>
          </ExpandIconContainer>
        ) : (
          <></>
        )}
        <Image isComplementary={isComplementary} src={API_URL + url} alt='' />
      </ImageContainer>
      <InfoContainer>
        <TWParagraph3
          align='left'
          color='#232323'
          weight={700}
          $style={{
            fontSize: '24px',
            paddingTop: '10px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {displayName}
        </TWParagraph3>
        <TWParagraph3
          $style={{
            fontSize: '18px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'break-spaces',
          }}
          color={theme.cannonColors.secondarySilver}
          weight={400}
          align='left'
        >
          {description}
        </TWParagraph3>
      </InfoContainer>
    </CardContainer>
  );
};

export default LockTypeCard;
